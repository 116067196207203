<template>
  <section
    class="Advantages"
    v-waypoint="{ active: true, callback: onWaypoint }"
  >
    <div class="Wrapper">
      <h3 class="Advantages-Title">
        Our advantages
      </h3>
      <div class="Advantages-Cards">
        <div
            v-for="(advantage, $advantage) in advantages"
            :key="$advantage"
            class="Advantages-Card"
            :class="advantage.class"
            style="opacity: 0; transform: scale(.9);"
        >
          <img
              :src="require(`@/assets/images/${advantage.icon}`)"
              alt="icon"
              class="Advantages-CardIcon"
          >
          <div class="Advantages-CardTitle">
            {{ advantage.title }}
          </div>
          <p
              class="Advantages-CardText"
              v-html="advantage.text"
          ></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Advantages",
  data() {
    return {
      isWaypointActive: false
    }
  },
  computed: {
    ...mapGetters({
      advantages: "getAdvantages"
    })
  },
  methods: {
    onWaypoint ({ going }) {
      // going: in, out
      // direction: top, right, bottom, left
      if (!this.isWaypointActive && going === this.$waypointMap.GOING_IN) {
        this.isWaypointActive = !this.isWaypointActive
        
        this.$anime.timeline().add({
          targets: '.Advantages-Card',
          opacity: 1,
          scale: 1,
          easing: 'easeInOutQuad',
          duration: 600,
          delay: function(el, i) { return i * 300 }
        })
      }
    }    
  }
}
</script>

<style lang="scss">
.Advantages {
  padding: 40px 0;
  background-color: var(--color-body);

  @include s-s {
    padding: 4.74vw 0 2.084vw;
  }

  @include s-xxl {
    padding: 67px 0 29px;
  }

  &-Title {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: var(--color-text-main2);
    text-transform: uppercase;
    
    @include s-s {
      margin-bottom: 5.374vw;
      font-size: 4.206vw;
      font-weight: 300;
      text-transform: normal;
    }

    @include s-l {
      margin-bottom: 3.438vw;
      font-size: 3.125vw;
    }
    
    @include s-xxl {
      margin-bottom: 48px;
      font-size: 44px;
    }
  }

  &-Cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-right: -10px;
    margin-left: -10px;
  }

  &-Card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(82, 0, 107, 0.15);

    @include s-s {
      width: 50%;
      flex: 0 0 auto;
      border-bottom: none;
      padding-bottom: 0;
    }

    @include s-m {
      width: 30%;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .automation {
    img {
      width: 8.2vw;
      max-width: 89px;
      width: auto;

      @include s-l {
        width: 5.157vw;
      }

      @include s-xxl {
        width: 73px;
      }
    }
  }

  .creativity {
    img {
      width: 9.4vw;
      max-width: 89px;
      width: auto;

      @include s-l {
        width: 6.72vw;
      }

      @include s-xxl {
        width: 95px;
      }
    }
  }

  .anti-fraud {
    img {
      width: 8.2vw;
      max-width: 89px;
      width: auto;

      @include s-l {
        width: 5.573vw;
      }

      @include s-xxl {
        width: 79px;
      }
    }
  }

  .quality {
    img {
      width: 9.4vw;
      max-width: 89px;
      width: auto;

      @include s-l {
        width: 5.521vw;
      }

      @include s-xxl {
        width: 78px;
      }
    }
  }

  .client {
    img {
      width: 9.4vw;
      max-width: 89px;
      width: auto;

      @include s-l {
        width: 5.104vw;
      }

      @include s-xxl {
        width: 72px;
      }
    }
  }

  &-CardIcon {
    margin-bottom: 1.563vw;

    @include s-xxl {
      margin-bottom: 22px;
    }
  }

  &-CardTitle {
    font-size: 22px;
    color: var(--color-text-main2);
    text-transform: uppercase;

    @include s-s {
      font-size: 3.28vw;
    }

    @include s-l {
      font-size: 1.823vw;
    }

    @include s-xxl {
      font-size: 25px;
    }
  }

  &-CardText {
    padding: 0 31px 0 35px;
    font-size: 18px;

    @include s-s {
      padding: 0;
      font-size: 2.81vw;
    }

    @include s-l {
      font-size: 1.3vw;
    }

    @include s-xxl {
      font-size: 18px;
    }
  }
}
</style>
