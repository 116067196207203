<template>
  <section class="ClientsJourney Wrapper"
    v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"
  >
    <h3 class="ClientsJourney-Title">
      Customer Journey
    </h3>
    <p class="ClientsJourney-Text">
      We attract only high-quality and solvent traffic
    </p>
    <div class="ClientsJourney-Rocket-Start"></div>
    <div class="ClientsJourney-Rocket">
      <ul class="ClientsJourney-List">
        <li
            v-for="(item, $item) in clientsJourney"
            :key="$item"
            class="ClientsJourney-Item"
            v-html="item.text"
        ></li>
      </ul>      
    </div>
    <div class="ClientsJourney-Stars">
      <svg width="613" height="613" viewBox="0 0 613 613" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M344.974 576.132C354.716 575.133 361.8 566.381 360.795 556.585C359.79 546.789 351.077 539.658 341.335 540.658C331.592 541.657 324.509 550.409 325.513 560.205C326.518 570.001 335.231 577.132 344.974 576.132Z" stroke="white" stroke-width="4" stroke-miterlimit="10"/>
        <path d="M516.931 323.675C519.597 323.402 521.536 321.007 521.261 318.326C520.986 315.645 518.601 313.693 515.935 313.966C513.269 314.24 511.33 316.635 511.605 319.316C511.88 321.997 514.265 323.949 516.931 323.675Z" fill="white"/>
        <path d="M76.37 70.257C76.37 70.257 80.0306 76.6737 82.6936 78.8532C85.9903 81.7225 92.7576 83.6697 92.7576 83.6697C92.7576 83.6697 86.3983 87.5295 84.3999 89.9986C82.4014 92.4676 79.4154 99.9435 79.4154 99.9435C79.4154 99.9435 75.774 93.7135 72.887 91.1796C70 88.6457 63.0469 86.7176 63.0469 86.7176C63.0469 86.7176 69.054 83.0826 71.4047 80.3887C74.0885 77.2833 76.37 70.257 76.37 70.257Z" fill="white"/>
        <path d="M109.209 357.444C109.209 357.444 110.143 359.235 110.943 359.719C111.782 360.576 113.696 360.946 113.696 360.946C113.696 360.946 111.935 362.07 111.435 362.687C110.936 363.304 110.032 365.472 110.032 365.472C110.032 365.472 109.117 363.868 108.279 363.011C107.459 362.34 105.711 361.765 105.711 361.765C105.711 361.765 107.306 360.847 107.972 360.024C108.453 359.22 109.209 357.444 109.209 357.444Z" fill="white"/>
        <path d="M8.08667 422.277C8.08667 422.277 10.1218 425.653 11.37 426.846C13.028 428.374 16.6902 429.319 16.6902 429.319C16.6902 429.319 13.3344 431.361 12.3352 432.595C11.336 433.83 9.67641 437.774 9.67641 437.774C9.67641 437.774 7.66042 434.584 6.20735 433.224C4.75427 431.864 1.09204 430.919 1.09204 430.919C1.09204 430.919 4.26212 428.896 5.44705 427.642C6.9459 425.79 8.08667 422.277 8.08667 422.277Z" fill="white"/>
        <path d="M131.464 274.409C141.207 273.41 148.291 264.658 147.286 254.862C146.281 245.066 137.568 237.935 127.825 238.935C118.083 239.934 110.999 248.685 112.004 258.481C113.009 268.277 121.722 275.409 131.464 274.409Z" stroke="white" stroke-width="4" stroke-miterlimit="10"/>
        <path d="M186.782 10.6302C189.448 10.3567 191.387 7.96153 191.112 5.28052C190.837 2.59951 188.452 0.647868 185.786 0.921404C183.12 1.19494 181.181 3.59007 181.456 6.27108C181.731 8.95209 184.116 10.9037 186.782 10.6302Z" fill="white"/>
        <path d="M50.0251 296.348C52.6916 296.074 54.6302 293.679 54.3551 290.998C54.0801 288.317 51.6956 286.366 49.0292 286.639C46.3627 286.913 44.4241 289.308 44.6992 291.989C44.9742 294.67 47.3587 296.621 50.0251 296.348Z" fill="white"/>
        <path d="M463.044 541.489C463.044 541.489 464.183 543.448 465.002 544.118C466.027 544.957 468.165 545.681 468.165 545.681C468.165 545.681 466.218 546.824 465.552 547.647C464.886 548.47 464.002 550.825 464.002 550.825C464.002 550.825 462.862 548.866 461.838 548.028C461 547.17 458.695 546.652 458.695 546.652C458.695 546.652 460.642 545.509 461.308 544.686C462.326 543.638 463.044 541.489 463.044 541.489Z" fill="white"/>
        <path d="M294.28 472.457C294.28 472.457 295.4 475.085 296.333 476.024C297.453 477.337 300.066 478.276 300.066 478.276C300.066 478.276 297.453 479.59 296.52 480.528C295.586 481.466 294.28 484.282 294.28 484.282C294.28 484.282 293.16 481.654 292.04 480.528C291.106 479.402 288.493 478.276 288.493 478.276C288.493 478.276 290.92 477.15 292.04 476.024C293.16 475.085 294.28 472.457 294.28 472.457Z" fill="white"/>
        <path d="M293.422 602.87C293.422 602.87 294.561 604.828 295.381 605.499C296.405 606.337 298.543 607.061 298.543 607.061C298.543 607.061 296.597 608.204 295.93 609.027C295.264 609.85 294.38 612.205 294.38 612.205C294.38 612.205 293.241 610.246 292.217 609.408C291.378 608.551 289.073 608.033 289.073 608.033C289.073 608.033 291.02 606.889 291.686 606.066C292.704 605.019 293.422 602.87 293.422 602.87Z" fill="white"/>
        <path d="M35.2714 474.459C35.2714 474.459 36.4105 476.417 37.2299 477.088C38.2541 477.926 40.3925 478.65 40.3925 478.65C40.3925 478.65 38.4457 479.793 37.7795 480.616C37.1133 481.439 36.229 483.794 36.229 483.794C36.229 483.794 35.0899 481.836 34.0657 480.997C33.2271 480.14 30.9222 479.622 30.9222 479.622C30.9222 479.622 32.869 478.479 33.5352 477.656C34.5536 476.608 35.2714 474.459 35.2714 474.459Z" fill="white"/>
        <path d="M410.76 467.578C410.76 467.578 411.693 469.642 412.44 470.393C413.373 471.331 415.427 472.27 415.427 472.27C415.427 472.27 413.373 473.208 412.627 473.959C411.88 474.71 410.76 476.962 410.76 476.962C410.76 476.962 409.827 474.897 408.893 473.959C408.147 473.021 405.907 472.27 405.907 472.27C405.907 472.27 407.96 471.331 408.707 470.581C409.827 469.83 410.76 467.578 410.76 467.578Z" fill="white"/>
      </svg>
      <div class="ClientsJourney-Planet"></div>
      <div class="ClientsJourney-Austronaut"></div>  
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ClientsJourney",
  data() {
    return {
      intersectionOptions: {
        root: null,
        threshold: [0.5, 0.5] // [0.25, 0.75] if you want a 25% offset!
      },
      isWaypointActive: false
    }
  },  
  computed: {
    ...mapGetters({
      clientsJourney: "getClientsJourney"
    })
  },
  mounted() {
    
  },
  methods: {
    onWaypoint ({ going }) {
      if (!this.isWaypointActive && going === this.$waypointMap.GOING_IN) {
        this.isWaypointActive = !this.isWaypointActive

        this.$anime.timeline().add({
          targets: '.ClientsJourney-Rocket',
          translateX: ['100%', 0],
          translateY: ['100%', 0],
          scale: [0, 1],
          easing: 'linear',
          duration: 1000,
          complete: () => {
            this.$anime({
              targets: '.ClientsJourney-Item',
              opacity: 1,
              easing: 'easeInOutSine',
              duration: 1000,
              delay: (el, i) => i * 100
            }, '-=1000')
          }
        })
        .add({
          targets: '.ClientsJourney-Stars',
          scale: [0, 1],
          opacity: [0, 1],
          easing: 'linear',
          duration: 500
        }, '-=500')   
      }
    }  
  }
}
</script>

<style lang="scss" >
.ClientsJourney {
  overflow: hidden;
  position: relative;
  height: 548px;
  padding-top: 12vw;
  padding-right: 0;
  padding-left: 0;
  counter-reset: list;
  background-image: linear-gradient(0deg, #301B4B -7.53%, #52006B 61.63%);
  background-position: 0 1px;
  background-repeat: no-repeat;
  background-color: var(--color-body1);
  
  @include s-l {
    height: 900px;
  }
  
  @include s-xxl {
    padding-top: 130px;
  }

  &:after {
    position: absolute;
    z-index: 3;
    content: "";
    top: 1px;
    left: 0;
    width: 100%;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
    border-top: 9.38vw solid var(--color-body1);

    @include s-xxl {
      border-width: 110px 700px 0;
    }
  }

  &-Title {
    position: relative;
    z-index: 3;
    font-weight: 300;
    font-size: 4.21vw;
    text-align: center;
    color: var(--color-text-main);

    @include s-l {
      font-size: 3.386vw;
    }

    @include s-xxl {
      font-size: 48px;
    }
  }

  &-Rocket {
    display: flex;
    align-items: flex-end;
    position: absolute;
    right: -40px;
    bottom: 0;
    width: 499px;
    height: 427px;
    background-image: url('~@/assets/images/anim/space-rocket-small.svg');
    background-repeat: no-repeat;
    will-change: transform;
    transform: scale(0) translate(100%, 100%);

    @include s-l {
      right: 0;
      width: 625px;
      height: 742px;      
      background-image: url('~@/assets/images/anim/space-rocket.svg');
    }    
  }

  &-Rocket-Start {
    @include s-l {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 305px;
      height: 350px;
      background-image: url('~@/assets/images/anim/space-rocket-start.svg');
      background-repeat: no-repeat;    
      pointer-events: none;
    }
  }

  &-List {
    position: relative;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    clip-path: polygon(309.554px 45.763px, 320.064px 61.768px, 330.575px 77.773px, 341.085px 93.778px, 351.596px 109.783px, 362.106px 125.788px, 372.617px 141.793px, 383.127px 157.798px, 393.637px 173.803px, 404.148px 189.808px, 414.658px 205.813px, 425.169px 221.818px, 435.679px 237.823px, 446.190px 253.828px, 456.700px 269.833px, 467.210px 285.838px, 477.721px 301.843px, 488.231px 317.848px, 498.009px 334.072px, 498.009px 353.219px, 498.009px 372.367px, 498.009px 391.514px, 498.009px 410.662px, 494.202px 426.003px, 475.055px 426.003px, 455.907px 426.003px, 436.760px 426.003px, 417.612px 426.003px, 398.465px 426.003px, 379.317px 426.003px, 360.170px 426.003px, 341.022px 426.003px, 321.874px 426.003px, 302.727px 426.003px, 283.579px 426.003px, 264.432px 426.003px, 245.284px 426.003px, 226.137px 426.003px, 206.989px 426.003px, 187.842px 426.003px, 168.694px 426.003px, 149.546px 426.003px, 130.399px 426.003px, 111.251px 426.003px, 92.104px 426.003px, 72.956px 426.003px, 53.809px 426.003px, 34.661px 426.003px, 15.514px 426.003px, 3.060px 424.043px, 19.018px 413.463px, 34.711px 402.492px, 50.151px 391.169px, 65.347px 379.519px, 80.299px 367.560px, 95.009px 355.302px, 109.471px 342.753px, 123.678px 329.918px, 137.623px 316.796px, 151.293px 303.389px, 164.673px 289.694px, 177.748px 275.706px, 190.497px 261.420px, 202.897px 246.830px, 214.921px 231.930px, 226.540px 216.711px, 237.718px 201.166px, 248.413px 185.285px, 258.581px 169.061px, 268.167px 152.487px, 277.113px 135.559px, 285.349px 118.276px, 292.801px 100.639px, 299.381px 82.660px, 304.999px 64.358px);
    
    @include s-l {
      clip-path: polygon(-789.365px 357.729px, -761.413px 422.006px, -728.124px 483.686px, -689.548px 542.203px, -645.811px 596.968px, -597.107px 647.364px, -543.703px 692.745px, -485.934px 732.417px, -424.220px 765.611px, -359.091px 791.458px, -291.250px 808.952px, -221.658px 816.949px, -151.676px 814.212px, -83.199px 799.608px, -18.827px 772.152px, 38.853px 732.469px, 89.083px 683.654px, 131.999px 628.273px, 167.951px 568.125px, 197.166px 504.426px, 219.555px 438.019px, 234.524px 369.565px, 241.177px 299.813px, 239.430px 229.764px, 229.560px 160.389px, 211.944px 92.564px, 257.695px 105.487px, 317.085px 142.705px, 372.785px 185.242px, 424.153px 232.918px, 470.502px 285.484px, 511.180px 342.550px, 545.651px 403.564px, 573.613px 467.825px, 594.982px 534.571px, 609.760px 603.080px, 617.988px 672.681px, 619.702px 742.746px, 614.903px 812.668px, 603.538px 881.825px, 585.481px 949.541px, 560.536px 1015.029px, 528.455px 1077.326px, 488.986px 1135.212px, 442.010px 1187.183px, 388.509px 1232.421px, 329.855px 1270.759px, 267.345px 1302.440px, 202.056px 1327.928px, 134.831px 1347.773px, 66.302px 1362.513px, -3.065px 1372.625px, -72.919px 1378.510px, -142.994px 1380.486px, -213.077px 1378.793px, -282.988px 1373.588px, -352.558px 1364.948px, -421.612px 1352.861px, -489.945px 1337.207px, -557.285px 1317.724px, -623.221px 1293.934px, -687.065px 1265.016px, -747.466px 1229.507px, -801.229px 1184.708px, -838.927px 1126.223px, -841.707px 1056.879px, -835.696px 987.030px, -830.548px 917.108px, -825.400px 847.186px, -820.252px 777.263px, -815.105px 707.341px, -809.957px 637.418px, -804.809px 567.496px, -799.661px 497.574px, -794.513px 427.652px);
    }
  }

  &-Item {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 225px;
    padding-right: 40px;    
    font-size: 14px;
    line-height: 1.1;
    text-align: center;
    color: var(--color-text-main2);
    z-index: 3;
    opacity: 0;
    transition-property: color, background-color;
    transition-timing-function: ease;
    transition-duration: 0.25s;

    @include s-l {
      padding-top: 32px;
      padding-bottom: 32px;      
      font-size: 22px;
    }

    &:hover {
      background-color: #FF5252;
      color: #fff;
    }

    &:before {
      content: counter(list) '. ';
      counter-increment: list;
    }

    & + & {
      border-top: 1px solid var(--color-bg-line);

      @include s-l {
        border-width: 2px;
      }
    }

    &:nth-child(4) {
      padding-right: 40px;
      padding-left: 147px;

      @include s-l {
        padding-right: 70px;
        padding-left: 170px;
      }
    }

    &:nth-child(3) {
      padding-right: 60px;
      padding-left: 200px;

      @include s-l {
        padding-right: 90px;
        padding-left: 240px;
      }
    }

    &:nth-child(2) {
      padding-right: 100px;
      padding-left: 235px; 

      @include s-l {
        padding-right: 135px;
        padding-left: 280px;
      }
    }

    &:nth-child(1) {
      padding-right: 135px;
      padding-left: 280px; 

      @include s-l {
        padding-right: 183px;
        padding-left: 262px;
      }

      &::before {
        display: block;
      }
    }
  }

  &-Stars {
    position: absolute;
    top: 23.93%;
    left: 4vw;
    width: 38.79vw;
    height: 38.79vw;
    pointer-events: none;
    transform: scale(0);
    opacity: 0;

    @include s-l {
      left: 23.93vw;
      width: 24.64vw;
      height: 24.64vw;      
    }

    @include s-xxl {
      top: 335px;
      left: 335px;      
      width: 345px;
      height: 345px;
    }

    > svg {
      width: 100%;
      height: 100%;
    }

    > svg > path {
      animation-name: shine;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(9),
      &:nth-child(12) {
        animation-duration: 2s;
      }

      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(8),
      &:nth-child(10) {
        animation-duration: 1.75s;
      }

      &:nth-child(3),
      &:nth-child(7),
      &:nth-child(11),
      &:nth-child(13) {
        animation-duration: 2.5s;
      }
    }
  }

  &-Planet {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;   
    background-image: url('~@/assets/images/anim/space-planet-01.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('~@/assets/images/anim/space-planet-02.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      animation: planet 60s linear infinite;
      opacity: 0.5;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('~@/assets/images/anim/space-planet-03.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }

  &-Austronaut {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 15.71vw;
    height: 15.71vw;
    background-image: url('~@/assets/images/anim/space-austronaut.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    animation: austronaut 10s linear infinite;
    will-change: transform;
    
    @include s-xxl {
      width: 220px;
      height: 220px;
    }
  }

  &-Text {
    font-weight: 500;
    font-size: 3.74vw;
    line-height: 1.1;
    text-align: center;
    color: var(--color-text-main);

    @include s-l {
      position: absolute;
      z-index: 3;
      bottom: 5%;
      left: 23%;
      width: 30%;
      width: 30vw;      
      font-size: 2.083vw;
    }

    @include s-xxl {
      width: 30%;
      font-size: 29px;
    }
  }
}

@keyframes austronaut {
  from {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(20px, -15px) scale(0.90);
  }
  50% {
    transform: translate(10px, 0) scale(0.98);
  }
  75% {
    transform: translate(-10px, 10px) scale(0.94);
  }
  to {
    transform: translate(0, 0) scale(1);
  }
}

@keyframes shine {
  from {
    opacity: 1;
    filter: blur(0);
  }
  50% {
    opacity: 0.6;
    filter: blur(1px);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes planet {
  to {
    transform: rotate(360deg);
  }
}
</style>
