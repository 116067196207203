<template>
  <div class="AboutBayer Wrapper">
    <div class="AboutBayer-Text">
      We work only with quality traffic sources<br>
      that bring the maximum benefit to our clients —<br>
      <span>
        high LTV, retention rates and superlative deposit amounts
      </span>
    </div>
    <div class="AboutBayer-Infos">
      <div
          v-for="(info, $info) in infos"
          :key="$info"
          class="AboutBayer-Info"
      >
        <div
            class="AboutBayer-InfoAmount"
            :class="info.class"
        >
          {{ info.amount }}
        </div>
        <p
            class="AboutBayer-InfoText"
            v-html="info.text"
        ></p>
      </div>
    </div>
    <div class="AboutBayer-Slider">
      <Partners
        partners-title="Our sources"
        :slider-info="sources" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Partners from "@/components/Partners";

export default {
  name: "AboutBayer",
  components: {Partners},
  data() {
    return {
      infos: [
        {
          amount: "30+",
          text: "direct traffic sources"
        },
        {
          amount: "50+",
          text: "BILLION APP DOWNLOADS"
        },
        {
          amount: "70+",
          text: "COUNTRIES OF THE WORLD"
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      sources: "getOurSources"
    })
  }
}
</script>

<style lang="scss">
.AboutBayer {
  transition: opacity 0.25s ease;

  &:not(&--ready) {
    opacity: 0;
  }

  &-Text {
    margin-bottom: 50px;
    font-weight: 300;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: var(--color-text-main1-light);

    @include s-s {
      margin-bottom: 7%;
      font-size: 2.8vw;
    }

    @include s-l {
      margin-bottom: 3%;
      font-size: 1.823vw;
    }

    @include s-xxl {
      font-size: 25px;
    }

    span {
      color: #740DAD;
    }
  }

  &-Infos {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 5%;
    text-transform: uppercase;
    color: var(--color-text-main2);
    
    @include s-s {
      flex-wrap: nowrap;
    }

    @include s-l {
      align-items: flex-end;
    }
  }

  &-Info {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
    padding-right: 10px;
    padding-left: 10px;

    @include s-s {
      flex: 0 0 29%;
      margin-bottom: 0;
    }
  }

  &-InfoAmount {
    font-size: 51px;
    line-height: 1;
    font-weight: 300;

    @include s-s {
      font-size: 4.842vw;
      font-weight: 400;
    }

    @include s-l {
      font-size: 3.646vw;
      line-height: 1.5;
    }

    @include s-xxl {
      font-size: 51px;
    }
  }

  &-InfoText {
    font-size: 16px;
    line-height: 1.5;
    
    @include s-s {
      font-size: 2.804vw;
    }

    @include s-l {
      font-size: 1.823vw;
    }

    @include s-xxl {
      font-size: 25px;
    }
  }
}
</style>
