<template>
  <section
    class="Clients"
    v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"
  >
    <div class="Clients-In Wrapper">
      <img src="@/assets/images/clients-bg-left.png" alt="" class="Clients-Bg BgLeft">
      <img src="@/assets/images/clients-bg-right.png" alt="" class="Clients-Bg BgRight">


      <h3 class="Clients-Title">
        Our Clients and Partners
      </h3>
      <div class="Clients-Wrapper">
        <svg class="Clients-Bg-SVG" width="1400" height="285" viewBox="0 0 1400 285" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g fill="transparent" fill-rule="evenodd" stroke-width="1" class="lines">
            <path class="Clients-Bg-SVG-path-1" d="M1.7187 213.152L233.667 142.624L465.615 213.152L233.667 283.68L1.7187 213.152Z"  stroke="#DBDBDB"/>
            <path class="Clients-Bg-SVG-path-1" d="M468.052 213.152L700 142.624L931.948 213.152L700 283.68L468.052 213.152Z"  stroke="#DBDBDB"/>
            <path class="Clients-Bg-SVG-path-1" d="M934.385 213.152L1166.33 142.624L1398.28 213.152L1166.33 283.68L934.385 213.152Z"  stroke="#DBDBDB"/>
            <path class="Clients-Bg-SVG-path-2" d="M1167.55 142.102L1399 71.7254V142.102L1399 212.478L1167.55 142.102Z"  stroke="#DBDBDB"/>
            <path class="Clients-Bg-SVG-path-2" d="M1.00035 142.102L1.00038 71.7254L232.449 142.102L1.0004 212.478L1.00035 142.102Z"  stroke="#DBDBDB"/>
            <path class="Clients-Bg-SVG-path-2" d="M234.885 142.102L466.833 71.5734L698.782 142.102L466.833 212.63L234.885 142.102Z"  stroke="#DBDBDB"/>
            <path class="Clients-Bg-SVG-path-2" d="M701.501 0L935.668 71.2029L701.501 142.406L467.334 71.2029L701.501 0Z"  stroke="#DBDBDB"/>
            <path class="Clients-Bg-SVG-path-2" d="M701.218 142.102L933.167 71.5734L1165.11 142.102L933.167 212.63L701.218 142.102Z"  stroke="#DBDBDB"/>
            <path class="Clients-Bg-SVG-path-3" d="M467.051 71.2029L699.5 0.522602L931.948 71.2029L699.5 141.883L467.051 71.2029Z"  stroke="#DBDBDB"/>
          </g>
        </svg>
        <div class="Clients-SliderWrapper">
          <swiper
              :options="options"
              class="Clients-Slider"
          >
            <swiper-slide
                v-for="(client, i) in clients"
                :key="i"
                class="Clients-Slide"
            >
              <div class="Clients-SlideContent">
                <div class="Clients-SlideRight">
                  <img
                      v-for="(icon, $icon) in client.right"
                      :key="'A' + $icon"
                      :src="require(`@/assets/images/${icon}`)"
                      alt="client icon"
                      class="Clients-SlideImg"
                  >
                </div>
                <div class="Clients-SlideLeft">
                  <img
                      v-if="client.left"
                      :src="require(`@/assets/images/${client.left}`)"
                      alt="client icon"
                      class="Clients-SlideImg"
                  >
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <svg width="81" height="63" class="Clients-SlideArrow Clients-SlidePrev" slot="button-prev">
          <use xlink:href="@/assets/images/icons-set.svg#double-chevron-left"></use>
        </svg>
        <svg width="81" height="63" class="Clients-SlideArrow Clients-SlideNext" slot="button-next">
          <use xlink:href="@/assets/images/icons-set.svg#double-chevron-right"></use>
        </svg>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import {Navigation} from "swiper";

export default {
  name: "Clients",
  data() {
    return {
      isWaypointActive: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0.75, 0.25] // [0.25, 0.75] if you want a 25% offset!
      },
      options: {
        modules: [Navigation],
        slidesPerView: 'auto',
        speed: 700,
        navigation: {
          nextEl: '.Clients-SlideNext',
          prevEl: '.Clients-SlidePrev'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      clients: "getClients",
      isMobile: 'isMobile'
    })
  },
  methods: {
    onWaypoint ({ going }) {
      // going: in, out
      // direction: top, right, bottom, left
      if (!this.isWaypointActive && going === this.$waypointMap.GOING_IN) {
        this.isWaypointActive = !this.isWaypointActive

        this.$anime.timeline().add({
          targets: '.Clients-Bg-SVG path',
          strokeDashoffset: [this.$anime.setDashoffset, 0],
          easing: 'easeInOutQuad',
          duration: 1000,
          complete: function () {
            document.querySelector('.Clients-SliderWrapper').classList.add('active')
            document.querySelector('.Clients-SlideNext').classList.add('active')
            document.querySelector('.Clients-SlidePrev').classList.add('active')
          }
        })        
      }
    }    
  }
}
</script>

<style lang="scss">
.Clients {
  position: relative;
  background-color: var(--color-body);
  padding: 10.42vw 0 2.5vw;

  @include s-l {
    padding: 10.42vw 0 0;
  }

  @include s-xxl {
    padding: 147px 0 0;
  }

  &-SlideImg {
    border-radius: 6px;
  }

  &-Bg-SVG {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    z-index: 1;

    @include s-m {
      bottom: -50px;
    }

    @include s-l {
      bottom: -25px;
    }

    @include s-xxl {
      bottom: -50px;
    }
  }

  &-Bg {
    position: absolute;
    top: -90px;
    z-index: -1;
  }

  .BgLeft {
    left: 0;
    z-index: -2;
  }
  .BgRight {
    right: 0;
  }

  &-Wrapper {
    position: relative;
  }

  &-Title {
    margin-bottom: 5.374vw;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: var(--color-text-main2);
    text-transform: uppercase;
  
    @include s-s {
      font-size: 4.206vw;
      font-weight: 300;
      text-transform: none;

    }

    @include s-l {
      margin-bottom: 4.583vw;
      font-size: 3.385vw;
    }

    @include s-xxl {
      margin-bottom: 65px;
      font-size: 48px;
    }
  }

  &-SliderWrapper {
    opacity: 0;
    margin: 0 auto;
    overflow-x: hidden;
    transition: opacity 0.25s ease-in-out;

    &.active {
      opacity: 1;
    }

    @include s-m {
      width: 72.917vw;
    }

    @include s-xxl {
      width: 1035px;
    }    
  }

  &-Slider {
    overflow: hidden;
  }

  &-Slide {
    @include s-m {
      padding: 0 8vw;
    }

    @include s-l {
      width: 23.125vw !important;
      padding: 0 5.1vw;
    }

    @include s-xxl {
      width: 328px !important;
      padding: 0 72px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &-SlideContent {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 18.69vw;
      flex: 0 0 auto;
      margin-right: 20px;
      
      @include s-m {
        margin-right: 0;
        width: 9.35vw;
      }
      
      @include s-l {
        width: 6.26vw;
      }
      
      @include s-xxl {
        width: 88px;
      }
    }
  }

  &-SlideLeft {
    flex: 0 0 auto;
  }

  &-SlideRight {
    display: flex;
    justify-content: center;
    align-items: center;

    @include s-m {
      margin-right: 15.188vw;
      flex-direction: column;
    }

    @include s-l {
      margin-right: 10.625vw;
    }

    @include s-xxl {
      margin-right: 150px;
    }

    img {
      &:last-child {
        @include s-m {
          margin-top: 5.374vw;
        }

        @include s-l {
          margin-top: 3.646vw;
        }

        @include s-xxl {
          margin-top: 51px;
        }
      }
    }
  }

  &-SlideArrow {
    position: absolute;
    width: 59px;
    top: 45%;
    transform: translate(0, -50%);
    cursor: pointer;
    stroke: var(--color-text-main2);
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    display: none;
    z-index: 1;

    @include s-m {
      display: block;
    }

    &.active {
      opacity: 1;
    }

    .desktop & {
      &:hover {
        stroke: var(--color-text-main1-light);
      }
    }
  }

  &-SlidePrev {
    left: 80px;
  }

  &-SlideNext {
    right: 80px;
  }
}
</style>
