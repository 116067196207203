import { render, staticRenderFns } from "./AboutBayer.vue?vue&type=template&id=545e255c&"
import script from "./AboutBayer.vue?vue&type=script&lang=js&"
export * from "./AboutBayer.vue?vue&type=script&lang=js&"
import style0 from "./AboutBayer.vue?vue&type=style&index=0&id=545e255c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports