<template>
  <div>
    <Header />
    <AboutBayer/>
    <ClientsJourney/>
    <Advantages/>
    <Clients/>
    <FormSection/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import AboutBayer from "@/components/AboutBayer";
import Advantages from "@/components/Advantages";
import ClientsJourney from "@/components/ClientsJourney";
import Clients from "@/components/Clients";
import FormSection from "@/components/FormSection";
import Footer from "@/components/Footer";

export default {
  name: "UserAcquisition",
  components: {
    Header, 
    Clients, 
    ClientsJourney, 
    Advantages, 
    AboutBayer,
    FormSection,
    Footer
  },
  metaInfo: {
    title: 'User Acquisition - WakeApp',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: "We Work With High Quality Traffic Sources that Generate the Maximum Benefit to Our Clients ✅ 8+ Years Expertise ✅ 300+ Professional Who Work for Result ✅ More Than 500 000 FTD Per Year",
    }],
  }
}
</script>
